import { IconContext } from "react-icons";
import "./MyListings.css"
import { FiPlusCircle } from "react-icons/fi";
import Header from './components/Header';
import Footer from './components/Footer';
import { MdOutlineShoppingCart } from "react-icons/md";
import { MdOutlineStorefront } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { db } from './firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';


const MyListings = () => {
  let navigate = useNavigate();
  const phoneNumber = localStorage.getItem("phoneNum");

  const checkIfDocumentExists = async () => {
    const collectionName = 'users';
    const documentId = `+90${phoneNumber}`;
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfFieldExists = async () => {
    const collectionName = 'users';
    const documentId = `+90${phoneNumber}`;
    const fieldName = 'isSeller';

    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];
      if (fieldValue !== undefined) {
        return fieldValue;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };


  const handleSearch = async () => {
    const exists = await checkIfDocumentExists();
    if (exists) {
      const fieldValue = await checkIfFieldExists();
      if (fieldValue === true) {

        navigate("/new-listing");
      } else if (fieldValue === false) {
        navigate("/new-seller");
      }else if (fieldValue === false) {
        navigate("/market-profile#");
      }
    } else {
      alert('Veritabanı Hatası');
    }
  };

  const TestLogin = () => {
      handleSearch();
  }
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }
  }, [navigate]); // navigate bağımlılığını ekliyoruz
  return (


    <div className='mcontainer'>
      <header><Header /></header>
      <div className="baslik">İlanlarım
        <div onClick={TestLogin} className='newadvert'>Yeni İlan &nbsp; <FiPlusCircle />
        </div>
      </div>
      <div className='ilanlarmain'>
        <div className='noadvert'>
          Listelenmiş İlanınız Bulunmamaktadır
        </div>
      </div>
      <div className='footerholddiv'><Footer /></div>
    </div>
  )
}

export default MyListings