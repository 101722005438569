import React, { useState, useRef, useEffect } from "react";
import { HiOutlineHome } from "react-icons/hi";
import { IconContext } from "react-icons";
import "./HamburgerMenu.css";
import { useNavigate } from "react-router-dom"
import { IoMdInformationCircleOutline } from "react-icons/io";
import { LuBook } from "react-icons/lu";
import { CgProfile } from "react-icons/cg";
import { TbLogout2 } from "react-icons/tb";




const HamburgerMenu = () => {
  let navigate = useNavigate();
  let path = "#"
  const routeChange = (event) => {
    let id = event.target.id
    if (id === 'homepage') {
      path = "/home";
    } else if (id === 'about') {
      path = "/about";
    } else if (id === 'privacy') {
      path = "/legal";
    } else if (id === 'login') {
      path = "/login";
    } else if (id === 'signup') {
      path = "/signup";
    } else if (id === 'profile') {
      path = "/profile";
    } else if (id === 'signout') {
      localStorage.removeItem("isLoggedIn", "false");
      path = "/login";
    }
    navigate(path);
  }
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);


  useEffect(() => {
    const handleClickOutside = (event) => {

      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleEscKey = (event) => {

      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };


    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);


    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  const isLoggedIn = localStorage.getItem("isLoggedIn");
  return (
    <div>
      {/* Hamburger Butonu */}
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="hamburgerButton"
      >
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </button>

      {/* Overlay Katmanı */}
      {isOpen && <div className="overlay" onClick={() => setIsOpen(false)}></div>}

      {/* Sağdan kayan menü */}
      <div
        ref={sidebarRef}
        className={`sidebar ${isOpen ? "open" : ""}`}
      >
        <div className="sidebarcolumn">
          <div className="topbuttons">

            <div onClick={routeChange} id="homepage" className="sidebaritem">
              <IconContext.Provider value={{ color: "black", className: "sidebaricon", size: "1.5em" }}>
                <HiOutlineHome />
              </IconContext.Provider>
              Ana Sayfa</div>

            <div onClick={routeChange} id="about" className="sidebaritem">
              <IconContext.Provider value={{ color: "black", className: "sidebaricon", size: "1.5em" }}>
                <IoMdInformationCircleOutline />
              </IconContext.Provider>
              Hakkında</div>


            <div onClick={routeChange} id="privacy" className="sidebaritem">
              <IconContext.Provider value={{ color: "black", className: "sidebaricon", size: "1.5em" }}>
                <LuBook />

              </IconContext.Provider>
              Gizlilik</div>

          </div>

          <div className="buttoncontainer" style={{ display: isLoggedIn ? "none" : "flex" }}>
            <button onClick={routeChange} id="login" className='login-button-sidebar'>Giriş Yap</button>
            <button onClick={routeChange} id="signup" className='signup-button-sidebar'>Kayıt Ol</button>
          </div>

          <div className="profilebuttoncontainer" style={{ display: isLoggedIn ? "flex" : "none" }}>  {/*  //sagdaki none olcak */}
            <div onClick={routeChange} id="profile" className="profilebutton">
              <IconContext.Provider value={{ color: "black", className: "profileicon", size: "2em" }}>
                <CgProfile />
              </IconContext.Provider>
              Profilim
            </div>
          <div onClick={routeChange} id="signout" className="signoutbutton">
          <IconContext.Provider value={{ color: "red", className: "profileicon", size: "2em" }}>
          <TbLogout2 />
              </IconContext.Provider>
          Çıkış Yap</div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
