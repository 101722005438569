import React, { useEffect, useState } from 'react'
import ImageUploading from 'react-images-uploading';
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./NewSeller.css"
import { useNavigate } from "react-router-dom";
import { MdInsertPhoto } from "react-icons/md";
import { IconContext } from "react-icons";
import { FaRegTrashCan } from "react-icons/fa6";
import { db } from "./firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore"
import CustomAlert from "./components/CustomAlert";
import CryptoJS from 'crypto-js';
const NewSeller = () => {
  const checkIfDocumentExists = async () => {
    const collectionName = 'keys';
    const documentId = `visualMaskKey`;

    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfFieldExists = async () => {
    const collectionName = 'keys';
    const documentId = `visualMaskKey`;
    const fieldName = 'key';

    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];
      if (fieldValue !== undefined) {
        return fieldValue;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };
  


  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
  });

  const showAlert = (message) => {
    setAlertData({ show: true, message });
  };

  const closeAlert = () => {
    setAlertData({ ...alertData, show: false });
  };

  const [sellerName, setSellerName] = useState("");
  const [sellerLocation, setSellerLocation] = useState("");
  const [images, setImages] = React.useState([]);
  const maxNumber = 1;
  const [phoneNumber] = useState(localStorage.getItem("phoneNum"));
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  let navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }
  }, [navigate]); // navigate bağımlılığını ekliyoruz


  const TestPush = () => {
    let regex = /^[a-zA-ZÇçŞşĞğİıÖöÜü\s]*$/;
    function capitalizeFirstLetter(inputText) {
      return inputText
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
    let formattedSellerName = capitalizeFirstLetter(sellerName);
    let formattedLocation = capitalizeFirstLetter(sellerLocation);
    if (sellerName === "" || sellerLocation === "") {
      showAlert("Tüm boşlukları doldurunuz.");
    } else if ((!(regex.test(formattedSellerName))) || (!(regex.test(formattedLocation)))) { //foto var mi yok mu test yok
      showAlert("Girdiler sadece Türkçe karakter içermelidir.");
    } else {
      async function addOrUpdateDataToCollection() {
        try {
          const handleSearch = async () => {
            const exists = await checkIfDocumentExists();
        
            if (exists) {
              const fieldValue = await checkIfFieldExists();
              const key = CryptoJS.enc.Utf8.parse(fieldValue); // 32-byte (256-bit) anahtar
              
              // Şifreleme Fonksiyonu
              const encryptPhoneNumber = (phoneNumber) => {
                const iv = CryptoJS.lib.WordArray.random(16); // Rastgele 16 byte IV oluştur
        
                // Şifreleme işlemi
                const encrypted = CryptoJS.AES.encrypt(phoneNumber, key, { iv: iv });
        
                // IV ve şifrelenmiş veriyi birleştir
                return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
              };
              
              const encrypted = encryptPhoneNumber(phoneNumber);
              await setDoc(doc(db, "users", `+90${phoneNumber}`), {
                isSeller: true,
                marketLocation: formattedLocation,
                marketName: formattedSellerName,
                marketLink: encrypted,
              }, { merge: true });

            } else {
              alert('HATA');
            }
          };
          handleSearch();
          navigate("/mylistings");
          console.log("Belge başarıyla eklendi veya güncellendi!");
        } catch (error) {
          console.error("Belge eklenirken hata oluştu:", error);
        }
      }
      addOrUpdateDataToCollection();
    }
  }

  return (
    <div className='mcontainer'>
      <header><Header /></header>
      <div className='newsellertitle'>Satıcı Profili
      </div>


      <div className='textboxes'>

        <div className='textboxtitle'>Satıcı Adı</div>
        <div className='textboxdiv'><input type="text" className='sellernameinput' id="sellernamebox" placeholder="Market / Satıcı Adı" onChange={
          (e) =>
            setSellerName(e.target.value)
        } ></input></div>
        <div className='textboxtitle'>Market Konumu</div>
        <div className='textboxdiv'><input type="text" className='sellernameinput' id="locationbox" placeholder="Market / Satıcı Konumu"
          onChange={
            (e) =>
              setSellerLocation(e.target.value)
          }></input></div>
        <div className='textboxtitle'>Profil Fotoğrafı</div>
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            // write your building UI
            <div className="upload__image-wrapper">
              {imageList.map((image, index) => (
                <div key={index} className="image-item">
                  <img className="secilenimg" src={image['data_url']} alt="" width="100" />
                </div>
              ))}
              <div className='buttons'>
                <button className='tiklaveyasuruklebuton'

                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                ><IconContext.Provider value={{ color: "black", className: "img-icon", size: "1.5em" }}>
                    <MdInsertPhoto />
                  </IconContext.Provider>
                  Resim Ekle
                </button>
                &nbsp;
                <button className="clearallbut" onClick={onImageRemoveAll}>
                  <IconContext.Provider value={{ color: "red", className: "trash-icon", size: "1.5em" }}>
                    <FaRegTrashCan />
                  </IconContext.Provider>
                </button>
              </div>


            </div>
          )}
        </ImageUploading>
        <div className='sendform' onClick={TestPush}>Gönder</div>
      </div>

      <div className='footer'><Footer />
      </div>
      {alertData.show && (
        <CustomAlert
          message={alertData.message} onClose={closeAlert}
        />
      )}
    </div>
  )
}

export default NewSeller