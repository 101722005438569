import React from 'react'
import logo from "../assets/logo-square.png"
import "./Footer.css"
import {useNavigate} from "react-router-dom";

const Footer = () => {
  let navigate = useNavigate();
  let path = "#" 
  const routeChange = (event) =>{ 
    let id = event.target.id
    if (id==='homepage'){
      path = "/home";
    }else if (id==='about'){
      path = "/about";
    }else if (id==='privacy'){
      path = "/legal";
    }else if (id==='login'){
      path = "/login";
    }else if (id==='signup'){
      path = "/signup";
    }
    navigate(path);
  }
  return (
    <div className='main-footer-container'>
      <div className='leftcollumns'>
      <div className='collumn'>
            <button onClick={routeChange} id="homepage" className='footerlabels' >Ana Sayfa</button>
            <button onClick={routeChange} id="about" className='footerlabels' >Hakkımızda</button>
            <button onClick={routeChange} id="privacy" className='footerlabels' >Gizlilik</button>
        </div>
        <div className='collumn'>
          <button onClick={routeChange} id="number" className='footerlabels' >+90 553 889 1515</button>
            <a href='mailto:mustafa@tarlanet.net'><button id="mailto" className='footerlabels' >mustafa@tarlanet.net</button></a> 
            <button  id="privacy" className='footerlabels' >Tüm hakları saklıdır.</button>
        </div>
      </div>
        
        <div className='collumntarla'>
          
          <img onClick={routeChange} id="homepage"alt='' src={logo} className='footerlogo'></img>
            <div onClick={routeChange} id="homepage" className='footertarlanet'><span onClick={routeChange} id="homepage" className='tarla'>Tarla</span>Net</div>
        </div>
    </div>
  )
}

export default Footer