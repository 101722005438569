import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Login.css"
import { IconContext } from "react-icons";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import rightphoto from "./assets/signupphoto.png";
import { useNavigate } from "react-router-dom";
import React, { useState , useEffect } from 'react';
import { db } from './firebaseConfig';  
import { doc, getDoc } from 'firebase/firestore';




const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");


  const checkIfDocumentExists = async () => {
    const collectionName = 'users';  
    const documentId = `+90${phoneNumber}`;  

    const docRef = doc(db, collectionName, documentId);  
    const docSnap = await getDoc(docRef);  

    if (docSnap.exists()) {
      return true;  
    } else {
      return false;  
    }
  };

  const checkIfFieldExists = async () => {
    const collectionName = 'users'; 
    const documentId = `+90${phoneNumber}`;  
    const fieldName = 'password';  

    const docRef = doc(db, collectionName, documentId);  
    const docSnap = await getDoc(docRef);  

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];  
      if (fieldValue !== undefined) {
        return fieldValue;  
      } else {
        return null;  
      }
    } else {
      return null;  
    }
  };

  const checkIfNameExists = async () => {
    const collectionName = 'users'; 
    const documentId = `+90${phoneNumber}`;  
    const fieldName = 'name';  

    const docRef = doc(db, collectionName, documentId);  
    const docSnap = await getDoc(docRef);  

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];  
      if (fieldValue !== undefined) {
        return fieldValue;  
      } else {
        return null;  
      }
    } else {
      return null;  
    }
  };

  const handleSearch = async () => {
    const exists = await checkIfDocumentExists();  

    if (exists) {
      const fieldValue = await checkIfFieldExists();  
      if (fieldValue === password) { 
        const fieldValue = await checkIfNameExists();  
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("phoneNum", phoneNumber);
        localStorage.setItem("name", fieldValue);
        navigate("/home");
      } else {
        alert('Hatalı Giriş!');  
      }
    } else {
      alert('Telefon veya şifre hatalı!');  
    }
  };
  const TestLogin = () => {
    let testNumber = phoneNumber;
    let numberOfDigits = testNumber.toString().length;
    let intValue = parseInt(numberOfDigits);
    if (phoneNumber === "" || password === "") {
      alert("Tüm boşlukları doldurunuz.")
    } else if (!(intValue === 10)) {
      alert("Geçerli bir telefon giriniz.")
    } else {
      handleSearch();
    }
  }

  let navigate = useNavigate();
  let path = "#"
  const routeChange = (event) => {
    let id = event.target.id
    if (id === 'signup') {
      path = "/signup";
    }
    navigate(path);
  }
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }
  }, [navigate]); // navigate bağımlılığını ekliyoruz
  return (
    <div className='mcontainer'>
      <div className='header_container'>
        <Header />
      </div>
      <div className='headerdiv'>
        <span className='headergiris'>Giriş Yap</span>
        <span className='subtitlegiris'>Eğer kayıtlı değilseniz <span onClick={routeChange} id="signup" className='kayitolun'>Kayıt Olun</span></span>

      </div>
      <div className='horizontaldiv'>
        <div className='leftdiv'>
          <span className='textboxuptext'>Telefon Numarası</span>
          <div className='phonediv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlinePhone />
            </IconContext.Provider>
            <input className='phonetextbox' type="number" placeholder="(5xx xxx xxxx)" onChange={
              (e) =>
                setPhoneNumber(e.target.value)
            } />
          </div>

          <span className='textboxuptext'>Şifre</span>

          <div className='passworddiv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlineLock />

            </IconContext.Provider>
            <input className='passwordtextbox' type="password" placeholder="Şifrenizi Giriniz" onChange={
              (e) =>
                setPassword(e.target.value)
            }></input></div>

          <div className='leftbottom'>
            <div className='sifremiunuttum'>Şifrenizi mi unuttunuz?</div>
          </div>

          <button onClick={TestLogin} className='girisyap'>Giriş Yap</button>
        </div>
        <div className='rightdiv'>
          <img src={rightphoto} className='rightphoto' alt=""></img>
        </div>

      </div>
      <footer><Footer /></footer>
    </div>

  )
}

export default Login