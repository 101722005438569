
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDr1JKVkqq3HyCB5POeYK3YuyQu7SdBfrI",
    authDomain: "tarlanet-122d3.firebaseapp.com",
    projectId: "tarlanet-122d3",
    storageBucket: "tarlanet-122d3.firebasestorage.app",
    messagingSenderId: "724739214110",
    appId: "1:724739214110:web:b20111952af98422a7570f",
    measurementId: "G-R1H82PKJKM"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export { db };
export default app;