import React from 'react'
import Header from "./components/Header.js"
import './App.css';
import "./About.css";
import photo from "./assets/home-title-photo.png"
import { FaLocationDot } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { LuWheat } from "react-icons/lu";
import detailphoto from "./assets/detail-photo.png"
import Footer from "./components/Footer.js"


const About = () => {
  return (
    <div className='main_container'>
      <div className='header_container'>
        <Header />
      </div>
      <div className='home-top-container'>
        <div className='home-top-left-container'>
          <label className='title1'>Çiftçiden Sofraya Dijital Bağlantı</label>
          <label className='subtitle1'>TarlaNet, çiftçilerin ürünlerini aracı olmadan tüketiciye ulaştırmasını sağlayan bir platformdur.</label>
          <div className='double-card'>
            <div className='leftcard'>
              <IconContext.Provider value={{
                color: "#1B8552",
                className: "location-icon",
                size: "3em"
              }}>
                <FaLocationDot />
              </IconContext.Provider>
              <div className='cardtitles'>Konuma Göre</div>
              <div className='cardsubtitles'>Aradığınız ürünleri konuma göre sıralayın</div>
            </div>
            <div className='rightcard'>
              <IconContext.Provider value={{ color: "#1B8552", className: "location-icon", size: "3em" }}>
                <LuWheat />
              </IconContext.Provider>
              <div className='cardtitles'>Ürüne Göre</div>
              <div className='cardsubtitles'>Ülke genelinde bulunan ürünleri filtreleyin</div>
            </div>
          </div>
        </div>
        <div className='home-top-right-container'>
          <img src={photo} className='firsphoto' alt="homepagephoto"></img>
        </div>

      </div>
      <div className='details-card'>
        Ne Amaçlıyoruz?
        <div className='details-horizontal-card'>
          <div className='details-left-card'>
            <img alt='detail' src={detailphoto} className='detailed-photo'></img>
          </div>
          <div className='details-right-card'>
            Yüksek aracılık maliyetlerini ortadan kaldırmak için buradayız. Türkiye'nin 81 ilinden organik ürünleri tarladan sipariş edebilir, kendi ürettiğiniz ürünleri tarladan çıkmadan satabilirsiniz. Bu şekilde tekelleşmiş aracıların alıcı ve satıcıların üzerine maddi yük olmasını engellemeye çalışıyoruz.
          </div>
        </div>
      </div>
      <div className='how-card'>
        <span className='titleborder'>Nasıl Çalışıyor?</span>
        <div className='how-card-h'>
          <div className='how-card-left'>
            <span className='nasilbasliksol'>Üreticiler</span>
            <div className='nasilaciklamasol'>Organik ürün üreticileri TarlaNet üzerinden ücretsiz hesap açar, kişisel bilgilerini ve satacakları ürünü sisteme yüklerler. TarlaNet kişiyle iletişime geçip ürünü doğrular. Ürün satışa hazır şekilde ilana çıkar.</div>
          </div>
          <div className='how-card-right'>
          <span className='nasilbasliksag'>Tüketiciler</span>
          <div className='nasilaciklamasag'>Tüketiciler TarlaNet üzerinden ücretsiz bir şekilde hesap açar, kişisel bilgilerini sisteme yüklerler. Daha sonra konuma göre veya ürüne göre ilanları listeleyip istedikleri ürünü satın alırlar.</div>
          </div>
        </div>

      </div>
      <footer><Footer/></footer>
    </div>
  )
}

export default About