import React, { useState , useEffect} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Signup.css";
import "./Login.css";
import { IconContext } from "react-icons";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import { FaIdCard } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import rightphoto from "./assets/loginphoto.png";
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import app from "./firebaseConfig";
import { db } from "./firebaseConfig";
import { MdOutlineSms } from "react-icons/md";
import { doc, setDoc } from "firebase/firestore"
import CustomAlert from "./components/CustomAlert";





const Signup = () => {
  const [alertData, setAlertData] = useState({
    show: false,
    message: "",
  });

  const showAlert = (message) => {
    setAlertData({ show: true, message });
  };

  const closeAlert = () => {
    setAlertData({ ...alertData, show: false });
  };

  
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullname, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const auth = getAuth(app);


  
  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "normal",
      callback: (response) => {
        const checkcode = document.getElementById('checkcode');
        checkcode.style.display = 'block';
        console.log("ReCAPTCHA doğrulandı:", response);
      },
    });
  };

  
  const handleSendCode = () => {
    let testNumber = phoneNumber;
    let numberOfDigits = testNumber.toString().length;
    let intValue = parseInt(numberOfDigits);
    let regex = /^[a-zA-ZÇçŞşĞğİıÖöÜü\s]*$/;
    if (phoneNumber === "" || password === "" || password2 === "" || fullname === "") {
      showAlert("Tüm boşlukları doldurunuz.");
    }else if(!(password === password2)){
      showAlert("Şifreler aynı değil.");
    }else if(!(intValue===10)){
      showAlert("Geçersiz telefon numarası.");
    }else if (!(regex.test(fullname))){
      showAlert("İsim sadece Türkçe karakter içermelidir.");
    }else{
      const dogrulamacontainer = document.getElementById('dogrulamacontainer');
      dogrulamacontainer.style.display = 'flex';
      const beforedogrulama = document.getElementById('beforedogrulama');
      beforedogrulama.style.display = 'none';

      setUpRecaptcha();
      const phoneNumberWithCountryCode = `+90${phoneNumber}`; 
      const appVerifier = window.recaptchaVerifier;

      signInWithPhoneNumber(auth, phoneNumberWithCountryCode, appVerifier)
        .then((confirmation) => {
          setConfirmationResult(confirmation);
          const recaptchacontainer = document.getElementById('recaptcha-container');
          recaptchacontainer.style.display = 'none';
          document.getElementById('dogrulamatext').innerHTML = "SMS Gönderildi"
          console.log("Kod gönderildi!");
        })
        .catch((error) => {
          console.error("Kod gönderme hatası:", error);
        });
    }

  };
  let navigate = useNavigate();
  let path = "#"
  const routeChange = (event) => {
    let id = event.target.id
    if (id === 'login') {
      path = "/login";
    }
    navigate(path);
  }
  
  const handleVerifyCode = () => {  //burası
    let numberOfDigits = verificationCode.toString().length;
    let intValue = parseInt(numberOfDigits);
    if(!(intValue===6)){
      showAlert("Geçersiz Kod!")
    }else{
      const otp = document.getElementById('checkcode');
      otp.style.display = 'none';
      if (confirmationResult) {
        confirmationResult
          .confirm(verificationCode)
          .then((result) => {
            const user = result.user;
            console.log("Kullanıcı doğrulandı:", result.user);
            function capitalizeFirstLetter(inputText) {
              return inputText
                .split(' ') 
                .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) 
                .join(' '); 
            }
            let formattedName = capitalizeFirstLetter(fullname);
            async function addOrUpdateDataToCollection() {
              try {
                await setDoc(doc(db, "users", `+90${phoneNumber}`), {
                  userId: user.uid,
                  name: formattedName,
                  email: "null",
                  activeListings: 0,
                  isSeller: false,
                  phone: `+90${phoneNumber}`,
                  createdAt: user.metadata.creationTime,
                  password: password,
  
                }, { merge: true });
                console.log("Belge başarıyla eklendi veya güncellendi!");
              } catch (error) {
                console.error("Belge eklenirken hata oluştu:", error);
              }
            }
            addOrUpdateDataToCollection();
            navigate("/login");
            
  
          })
          .catch((error) => {
            console.error("Kod doğrulama hatası:", error);
            showAlert("Doğrulama başarısız. Tekrar deneyin.");
          });
      }
    }
    
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }
  }, [navigate]); // navigate bağımlılığını ekliyoruz

  

  return (
    <div className='wholecontainer'>
      <div className='header_container'>
        <Header />
      </div>
      <div className='headerdiv'>
        <span id="baslik" className='headergiris'>Kayıt Ol</span>
        <span className='subtitlegiris'>Eğer kayıtlıysanız <span onClick={routeChange} id="login" className='kayitolun'>Giriş Yapın</span></span>

      </div>
      <div className='horizontaldiv'>
        <div className='leftdiv'>
          <div id="beforedogrulama" className='beforedogrulama'>
            <span className='textboxuptext'>Ad ve Soyad</span>
            <div className='phonediv'>
              <IconContext.Provider value={{
                color: "#1B8552",
                size: "2.4em"
              }}>
                <FaIdCard />
              </IconContext.Provider>
              <input id="isim" className='phonetextbox' type="text" placeholder="Tam Adınızı Giriniz"
                onInput={(e) =>

                  setFullName(e.target.value)
                }></input>
            </div>
            <span className='textboxuptext'>Telefon Numarası</span>

            <div className='phonediv'>
              <IconContext.Provider value={{
                color: "#1B8552",
                size: "2.5em"
              }}>
                <MdOutlinePhone />
              </IconContext.Provider>
              <input id="numara" className='phonetextbox' type="number" placeholder="(5xx xxx xxxx)" pattern="5[0-9]{2} [0-9]{3} [0-9]{4}" required value={phoneNumber}
                onChange={
                  (e) =>
                    setPhoneNumber(e.target.value)
                }></input>

            </div>

            <span className='textboxuptext'>Şifre</span>

            <div className='passworddiv'>
              <IconContext.Provider value={{
                color: "#1B8552",
                size: "2.5em"
              }}>
                <MdOutlineLock />

              </IconContext.Provider>
              <input id="sifre" className='passwordtextbox' type="password" placeholder="Şifrenizi Giriniz"
                onChange={
                  (e) =>
                    setPassword(e.target.value)
                }></input></div>

            <span className='textboxuptext'>Tekrar Şifre </span>

            <div className='passworddiv'>
              <IconContext.Provider value={{
                color: "#1B8552",
                size: "2.5em"
              }}>
                <MdOutlineLock />

              </IconContext.Provider>
              <input id="sifretekrar" className='passwordtextbox' type="password" placeholder="Şifrenizi Giriniz"
                onChange={
                  (e) =>
                    setPassword2(e.target.value)
                }></input></div>



            {/* <div className='leftbottom'>
            <div className='rememberdiv'>
              <input className='rememberme' type='checkbox'></input>
              <span className='benihatirla'>Beni Hatırla</span>
              </div>
          </div> */}
            <button className='girisyap' onClick={handleSendCode}>Kayıt Ol</button>
          </div>
          <div id="dogrulamacontainer" className='dogrulamacontainer'>
            <span id="dogrulamatext" className='textboxuptext'>Doğrulama Kodu</span>

            <div className='passworddiv'>
              <IconContext.Provider value={{
                color: "#1B8552",
                size: "2.5em"
              }}>
                <MdOutlineSms />


              </IconContext.Provider>
              <input id="otp" className='passwordtextbox' type="text" placeholder="Doğrulama Kodunu Giriniz" value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}></input></div>
            <div id="recaptcha-container" className='recaptcha-container'></div>

            <button className='checkcode' id='checkcode' onClick={handleVerifyCode}>Kodu Doğrula</button>
          </div>


          {/* <button className='girisyap'>Kayıt Ol</button> */}
        </div>
        <div className='rightdiv'>
          <img src={rightphoto} className='rightphoto' alt=""></img>
        </div>

      </div>
      <footer><Footer /></footer>
      {alertData.show && (
        <CustomAlert 
          message={alertData.message} onClose={closeAlert} 
        />
      )} 
    </div>
  )

}

export default Signup