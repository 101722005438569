import { MdOutlineLocationOn } from "react-icons/md";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IconContext } from "react-icons";
import { db } from "./firebaseConfig";
import { doc, getDoc } from "firebase/firestore"
import React, { useEffect } from 'react'
import "./MarketProfile.css"
import Header from './components/Header'
import Footer from './components/Footer'
import DefaultPhoto from "../src/assets/defaultpp.png"
import CryptoJS from 'crypto-js';

const MarketProfile = () => {

  const checkIfNameExists = async () => {
    const collectionName = "keys";
    const documentId = "visualMaskKey";
    const fieldName = "key";

    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];
      if (fieldValue !== undefined) {
        return await fieldValue;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  
  
  let navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }

    const handleSearch = async () => {
      const fieldValue = await checkIfNameExists();
      console.log(fieldValue);
      if (!fieldValue) {
        console.log("Anahtar değeri bulunamadı.");
        return;
      }
      
      const key = CryptoJS.enc.Utf8.parse(fieldValue); // 32-byte (256-bit) anahtar
      const hash = window.location.hash;
      const value = hash ? hash.substring(1) : null;
      console.log(value)
      if (!value) {
        console.log("Hash değeri bulunamadı.");
        return;
      }
    
      const decryptTag = (encryptedData) => {
        const [ivBase64, encrypted] = encryptedData.split(':');
        if (!ivBase64 || !encrypted) {
          console.log("Geçersiz şifreli veri.");
          return null;
        }
    
        const iv = CryptoJS.enc.Base64.parse(ivBase64); // IV'yi Base64'ten çözüyoruz
    
        try {
          // Şifre çözme işlemi
          const decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv });
          const decryptedText = decrypted.toString(CryptoJS.enc.Utf8); // Orijinal metne geri dön
    
          if (!decryptedText) {
            console.log("Şifre çözme başarısız.");
          }
    
          return decryptedText;
        } catch (error) {
          console.log("Şifre çözme hatası:", error);
          return null;
        }
      };
    
      const decrypted = decryptTag(value);
      if (decrypted) {
        console.log('Çözülmüş Veri:', decrypted);
      } else {
        console.log("Veri çözülemedi.");
      }
    };
    
    handleSearch();
    
    

  }, [navigate]); // navigate bağımlılığını ekliyoruz
  return (
    <div className='maincontainer'>
      <Header />
      <div className='profilemaincont'>
        <div className='topinfos'>
          <div className='profileimg'>
            <img alt="" className='pp' src={DefaultPhoto}></img>
          </div>
          <div className='companyname'>Kardeşler Çiftçilik</div>
        </div>
        <div className='middleinfos'>
          <div className='middledivs'>
            <IconContext.Provider value={{ color: "black", className: "locationicon", size: "1.5em" }}>
              <MdOutlineLocationOn />
            </IconContext.Provider>
            Konya
          </div>
          <div className='middledivs'>
            <IconContext.Provider value={{ color: "black", className: "calendar-icon", size: "1.5em" }}>
              <MdOutlineCalendarMonth />

            </IconContext.Provider>
            12 Gün
          </div>
        </div>
        <br></br>
        <div className="ilanlardiv">
          İLAN YOK
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default MarketProfile