import React from 'react'
import { SpeedInsights } from "@vercel/speed-insights/react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import About from "./About";
import Signup from './Signup';
import Login from './Login';
import Home from './Home';
import Errorpage from "./Errorpage"
import Legal from "./Legal"
import Profile from "./Profile"
import NewSeller from "./NewSeller.js"
import NewListing from "./Newlisting.js"
import MarketProfile from "./MarketProfile.js"
import MyListings from './MyListings.js';
import NewOrder from './NewOrder.js';
import Listings from './Listings.js';
const App = () => {
  return (
    <Router>
            <Routes>
              <Route path="*" element={<Errorpage/>} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/listings" element={<Listings />} />
                <Route exact path="/new-crate-order" element={<NewOrder />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/" element={<Home />} />
                <Route exact path="" element={<Home />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/legal" element={<Legal />} />
                <Route exact path="/market-profile" element={<MarketProfile/>}/>
                <Route exact path="/new-seller" element={<NewSeller />} />
                <Route exact path="/new-listing" element={<NewListing />} />
                <Route exact path="/profile" element={<Profile />} />
                <Route exact path="/mylistings" element={<MyListings />} />
            </Routes>
            <SpeedInsights/>
          
        </Router>

  )
}

export default App