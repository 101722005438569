import React from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import "./Legal.css";

const Legal = () => {
  return (
    <div className='main_container'>
      <div className='header_container'><Header /></div>
      <div className='containerpolicy'>
        <div className='policytitle'>Gizlilik Sözleşmesi</div>
        <div className='text'>TarlaNet olarak, kullanıcılarımızın gizliliğine büyük önem veriyoruz. Bu gizlilik sözleşmesi, TarlaNet platformunu kullandığınız sırada kişisel bilgilerinizin nasıl toplandığını, kullanıldığını ve korunduğunu açıklamaktadır. Platformumuzu kullanarak bu gizlilik sözleşmesinin koşullarını kabul etmiş sayılırsınız.

          TarlaNet, kullanıcılarından belirli bilgileri toplar. Bu bilgiler, kayıt işlemleri sırasında sağlanan ad, soyad, e-posta adresi, telefon numarası, adres bilgileri gibi kişisel bilgileri ve platformun kullanımı sırasında otomatik olarak toplanan IP adresi, tarayıcı bilgileri ve çerez verilerini içerebilir. Bu bilgiler, hizmetlerimizi sunabilmek, kullanıcı deneyimini iyileştirmek ve yasal gerekliliklere uyum sağlamak amacıyla kullanılacaktır.
          Kişisel bilgileriniz, yalnızca TarlaNet'in hizmetlerini sağlamak için gerekli olduğu durumlarda üçüncü taraflarla paylaşılabilir. Örneğin, siparişlerinizi teslim edebilmek için kargo firmalarıyla paylaşmamız gerekebilir. Bunun dışında, bilgileriniz izniniz olmadan herhangi bir üçüncü tarafla paylaşılmayacaktır.
          Bilgilerinizin güvenliğini sağlamak için gelişmiş teknolojiler ve yöntemler kullanıyoruz. Ancak, internet üzerinden bilgi aktarımının tamamen güvenli olmadığını unutmayınız. TarlaNet, makul güvenlik önlemlerine rağmen meydana gelebilecek herhangi bir veri ihlali durumunda sorumluluğu minimize etmek için gerekli tüm adımları atar ve kullanıcılarını bilgilendirir.
          TarlaNet, yasal gerekliliklere uymak, yasal talepleri karşılamak veya kullanıcılarımızın güvenliğini sağlamak amacıyla kişisel bilgilerinizi açıklamak zorunda kalabilir. Bunun dışında, bilgilerinizi yalnızca sizin açık izninizle paylaşırız.
          Platformumuz, TarlaNet haricinde üçüncü taraf internet sitelerine bağlantılar içerebilir. Bu sitelerin gizlilik uygulamaları üzerinde bir kontrolümüz bulunmamaktadır. Bu nedenle, üçüncü taraf sitelerini kullanmadan önce onların gizlilik politikalarını dikkatlice okumanızı öneririz.
          TarlaNet, zaman zaman bu gizlilik sözleşmesinde değişiklik yapma hakkını saklı tutar. Bu sayfa üzerinde yapılan değişiklikler, yayına alındığı tarihten itibaren geçerli sayılır. Kullanıcılarımızın, bu sözleşmeyi düzenli olarak kontrol etmeleri önerilir.
          Kişisel verileriniz ve gizliliğiniz hakkında herhangi bir sorunuz veya talebiniz olması durumunda bizimle iletişime geçmekten çekinmeyin. İletişim bilgilerimiz web sitemizde yer almaktadır.
          Bu gizlilik sözleşmesi, TarlaNet platformunu kullanan tüm kullanıcılar için geçerlidir ve platformumuzun bir parçası olarak kabul edilir.</div>
      </div>
      <div className='containerpolicy'>
        <div className='policytitle'>Satıcı Sözleşmesi</div>
        <div className='text'>TarlaNet platformunda hizmet sunan tüm satıcılar, aşağıdaki şartları kabul eder ve bu şartlara uymayı taahhüt eder:
          TarlaNet, satıcıların kişisel ve ticari bilgilerini gizli tutmayı taahhüt eder. Satıcılarımızdan alınan bilgiler (örneğin, ticari unvan, vergi numarası, iletişim bilgileri ve banka hesap bilgileri), yalnızca TarlaNet’in platform hizmetlerini sağlamak, ödeme süreçlerini gerçekleştirmek ve yasal yükümlülükleri yerine getirmek amacıyla kullanılır.
          Satıcılar, platformda paylaştıkları ürün veya hizmet bilgileri ile ilgili olarak tamamen sorumludur. TarlaNet, satıcıların sağladığı bilgilerin doğruluğunu ve yasalara uygunluğunu garanti etmez, ancak şikayet durumunda gerekli incelemeyi yapma ve ihlal tespit edilirse ilgili içeriği kaldırma hakkını saklı tutar.
          Satıcılar, müşteri bilgilerini yalnızca sipariş işlemlerini gerçekleştirmek için kullanmayı kabul eder. Müşteri bilgileri, üçüncü şahıslara satılamaz, devredilemez veya başka bir amaçla kullanılamaz.
          TarlaNet, satıcılara ait ticari bilgilerin korunması için gerekli güvenlik önlemlerini alır. Ancak, çevrimiçi platformlarda meydana gelebilecek teknik aksaklıklar veya yetkisiz erişim durumlarında TarlaNet’in sorumluluğu, yalnızca yasal yükümlülüklerle sınırlıdır.
          Satıcılar, platformun işleyişini bozacak veya diğer kullanıcıların deneyimini olumsuz etkileyecek herhangi bir faaliyette bulunmamayı kabul eder. TarlaNet, bu tür faaliyetlerde bulunan satıcıların hesaplarını askıya alma veya sonlandırma hakkına sahiptir.
          Bu sözleşme, TarlaNet’in satıcılara sunduğu tüm hizmetler için geçerlidir. TarlaNet, gerektiğinde bu sözleşmeyi güncelleme hakkını saklı tutar ve yapılan değişiklikler, satıcılara bilgilendirme yapıldıktan sonra geçerli olur.
          Sorularınız veya desteğe ihtiyaç duyduğunuz konularda TarlaNet Satıcı Destek Ekibi ile iletişime geçebilirsiniz.</div>
      </div>
      <div className='containerpolicy'>
        <div className='policytitle'>Alıcı Sözleşmesi</div>
        <div className='text'>TarlaNet platformunu kullanan tüm alıcılar, aşağıdaki şartları kabul eder ve bu şartlara uymayı taahhüt eder:

          TarlaNet, alıcıların kişisel bilgilerini korumayı taahhüt eder. Kayıt sırasında veya sipariş sürecinde alınan bilgiler (ad, soyad, iletişim bilgileri, ödeme bilgileri) yalnızca siparişlerinizi işlemek, teslimatları gerçekleştirmek ve yasal yükümlülükleri yerine getirmek için kullanılır.
          Alıcılar, platform üzerinden verilen siparişlerde sağladıkları bilgilerin doğru ve eksiksiz olduğunu taahhüt eder. Yanlış veya eksik bilgi verilmesinden kaynaklanan sorunlardan TarlaNet sorumlu tutulamaz.
          Platformda sunulan ürün ve hizmetlerle ilgili tüm sorumluluk, bu ürün veya hizmetleri sağlayan satıcılara aittir. TarlaNet, satıcıların beyan ettiği ürün özellikleri veya hizmet kalitesi konusunda garanti vermez, ancak şikayet durumunda gerekli incelemeleri yapma ve gerektiğinde müdahale etme hakkını saklı tutar.
          Alıcılar, platformu yalnızca kişisel ve yasal amaçlarla kullanmayı kabul eder. TarlaNet’i dolandırıcılık, yanıltıcı işlemler veya diğer yasa dışı faaliyetler için kullanmak yasaktır ve tespit edilmesi durumunda hukuki işlem başlatılabilir.
          TarlaNet, ödeme işlemlerinde güvenliği sağlamak için gerekli önlemleri alır. Ancak, çevrimiçi işlemlerde yaşanabilecek teknik aksaklıklar veya üçüncü taraflardan kaynaklanan sorunlar için sorumluluk, yalnızca yasal sınırlar çerçevesindedir.
          Bu sözleşme, TarlaNet platformunda yapılan tüm alışverişler için geçerlidir. TarlaNet, gerektiğinde bu sözleşmeyi güncelleme hakkını saklı tutar ve yapılan değişiklikler, alıcılara bilgilendirme yapıldıktan sonra geçerli olur.
          Sorularınız veya şikayetleriniz için TarlaNet Destek Ekibi ile iletişime geçebilirsiniz.</div>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Legal