import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import "./NewOrder.css"
import { FaCartPlus } from "react-icons/fa6";
import { IconContext } from "react-icons";
import { useNavigate } from "react-router-dom";
import CustomAlert from "./components/CustomAlert";
const NewOrder = () => {
  let navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/home");
    }
  }, [navigate]);
  const [name] = useState(localStorage.getItem("name"));

  const [detailedAdress, setDetailedAdress] = useState("");
  const [cities, setCities] = useState([]); // Şehir listesi
  const [filteredCities, setFilteredCities] = useState([]); // Filtrelenmiş şehir listesi
  const [districts, setDistricts] = useState([]); // İlçe listesi
  const [filteredDistricts, setFilteredDistricts] = useState([]); // Filtrelenmiş ilçe listesi
  const [searchTerm, setSearchTerm] = useState(""); // Şehir arama kutusu değeri
  const [districtSearchTerm, setDistrictSearchTerm] = useState(""); // İlçe arama kutusu değeri
  const [selectedCity, setSelectedCity] = useState(""); // Seçilen şehir SONUÇ
  const [selectedDistrict, setSelectedDistrict] = useState(""); // Seçilen ilçe SONUÇ
  const [isCityDropdownOpen, setCityDropdownOpen] = useState(false); // Şehir dropdown durumu
  const [isDistrictDropdownOpen, setDistrictDropdownOpen] = useState(false); // İlçe dropdown durumu
  const [isTextareaVisible, setTextareaVisible] = useState(false); // Textarea'nın görünür olma durumu
  // API'den şehirleri çek
  useEffect(() => {
    fetch("https://turkiyeapi.dev/api/v1/provinces")
      .then((response) => response.json())
      .then((data) => {
        setCities(data.data);
        setFilteredCities(data.data);
      })
      .catch((error) => console.error("Şehirler yüklenirken hata oluştu:", error));
  }, []);

  // Şehir arama kutusunda yazıldığında filtreleme
  const handleCitySearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = cities.filter((city) =>
      city.name.toLowerCase().includes(term)
    );
    setFilteredCities(filtered);
  };

  // İlçe arama kutusunda yazıldığında filtreleme
  const handleDistrictSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setDistrictSearchTerm(term);
    const filtered = districts.filter((district) =>
      district.name.toLowerCase().includes(term)
    );
    setFilteredDistricts(filtered);
  };

  // Şehir seçildiğinde
  const handleCitySelect = (cityName) => {
    setSelectedCity(cityName);
    setSearchTerm(cityName);
    setCityDropdownOpen(false);

    const city = cities.find((c) => c.name === cityName);
    if (city) {
      setDistricts(city.districts);
      setFilteredDistricts(city.districts); // İlçeleri filtrele
    } else {
      setDistricts([]);
      setFilteredDistricts([]);
    }
    setSelectedDistrict("");

  };

  // İlçe seçildiğinde
  const handleDistrictSelect = (districtName) => {
    setSelectedDistrict(districtName);
    setDistrictSearchTerm(districtName);
    setDistrictDropdownOpen(false);
    setTextareaVisible(true); // İlçe seçildikten sonra textarea'nın görünmesini sağla
  };

  // Dropdown menüsünün kapanmaması için onBlur ve onFocus yönetimi
  const handleCityDropdownFocus = () => setCityDropdownOpen(true);
  const handleDistrictDropdownFocus = () => setDistrictDropdownOpen(true);

  const [showAlert, setShowAlert] = useState(false);


  const [cards, setCards] = useState([]); // Kartları saklamak için state

  const addCard = () => {
    setShowAlert(true)
    // Yeni bir kart eklemek için bir id ve başlık ile oluşturuyoruz
    const newCard = {
      id: cards.length + 1,
      title: `Kart ${cards.length + 1}`,
      content: `Bu, ${cards.length + 1}. kartın içeriğidir.`
    };
    
    // Yeni kartı mevcut kartların listesine ekliyoruz
    setCards([...cards, newCard]);
  };


  return (

    <div className='main-container'>
      <Header />
      <div className='title4step'> 4 Adımda Kasanı <u className='yesilboya'><span className='siyahboya'>Sipariş Et.</span></u></div>
      <div className='stepsmaindiv'>
        <div className='adim1div'>
          <div className='adimbaslik'>1-Adres</div>
          <div className="dropdown-container">
            {/* Şehir Arama Kutusu */}
            <div className="search-bar-container">
              <input
                type="text"
                value={searchTerm}
                placeholder="İl Seçimi"
                onChange={handleCitySearch}
                onFocus={handleCityDropdownFocus}
                onBlur={() => setTimeout(() => setCityDropdownOpen(false), 200)}
                className="search-bar"
              />
              {isCityDropdownOpen && filteredCities.length > 0 && (
                <div className="dropdown-menu">
                  {filteredCities.map((city) => (
                    <div
                      key={city.id}
                      className="dropdown-item"
                      onClick={() => handleCitySelect(city.name)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {city.name}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* İlçe Arama Kutusu ve Dropdown */}
            {districts.length > 0 && (
              <div className="search-bar-container">
                <input
                  type="text"
                  value={districtSearchTerm}
                  placeholder="İlçe Seçimi"
                  onChange={handleDistrictSearch}
                  onFocus={handleDistrictDropdownFocus}
                  onBlur={() => setTimeout(() => setDistrictDropdownOpen(false), 200)}
                  className="search-bar"
                />
                {isDistrictDropdownOpen && filteredDistricts.length > 0 && (
                  <div className="dropdown-menu">
                    {filteredDistricts.map((district) => (
                      <div
                        key={district.id}
                        className="dropdown-item"
                        onClick={() => handleDistrictSelect(district.name)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {district.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
          {isTextareaVisible && (
            <div className="textarea-container">
              <textarea
                rows="5" cols="30"
                placeholder="Adres Detayı"
                className="adresdetay"
                onChange={
                  (e) =>
                    setDetailedAdress(e.target.value)
                }
              ></textarea>
            </div>
          )}

        </div>
        <div className='adim2div'>
          <div className='adimbaslik'>2-Özelleştirme</div>
        </div>
        <button onClick={addCard} className='addneworder'> <IconContext.Provider value={{ color: "white", className: "cart", size: "1.5rem" }}>
          <FaCartPlus />
        </IconContext.Provider>
          Ürün Ekle</button>
          
        <div className='cardholdermaindiv'>
        {cards.map((card) => (
          <div id={card.id} className="card">
            <h3>{card.title}</h3>
            <p>{card.content}</p>
          </div>
        ))}
        </div>
      </div>
      <footer><Footer /></footer>
      {showAlert && (
        <CustomAlert 
          message="Yeni Sipariş Eklendi" 
          onClose={() => setShowAlert(false)} 
        />
      )}
    </div>
  )
}

export default NewOrder