import React from 'react'
import Header from "./components/Header";
import Footer from "./components/Footer";
import Search from "./components/Search";
import "./Home.css";
import Crate from "./assets/home-crate.png"
import HomeListings from "./assets/home-listings.png"
import { useNavigate } from "react-router-dom";

const Home = () => {
  let navigate = useNavigate();
  let path = "#"
  const routeChange = (event) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let id = event.target.id
    if (id === 'hemenalin') {
      if (isLoggedIn === "true") {
        path = "/new-crate-order";
      }else{
        path = "/login";
      }
    }else if (id === 'listeler') {
      path = "/listings";
    }
    navigate(path);
  }

  return (
    <div className='mcontainer'>
        <div className='headercontainer'>
            <Header/>
        </div>
        <div className='crate-div'>
          <div className='cratephotodiv'>
            <img alt='homepage' className="cratephoto" src={Crate}></img>
          </div>
          <div className='cratetitle'>
          Haftalık Organik Paketinizi <u className='yesilboya'><span id="hemenalin" onClick={routeChange} className='paketihazirla'>Hemen Alın<span className='yesilboya'>.</span></span></u>
        </div>
        </div>
        <div className="yada">-veya-</div>
        <div className='crate-div2'>
          
          <div className='cratetitle2'>
          Listelenmiş <u className='yesilboya'><span id="listeler" onClick={routeChange} className='paketihazirla'>Ürünleri Görün<span className='yesilboya'>.</span></span></u>
        </div>
        <div className='cratephotodiv2'>
            <img alt='homepage' className="cratephoto" src={HomeListings}></img>
          </div>
        </div>
        <Search/>
        <footer><Footer/></footer>
    </div>
  )
}

export default Home