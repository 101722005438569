import React, { useEffect, useState } from 'react'
import Header from "./components/Header"
import Footer from "./components/Footer"
import PP from "../src/assets/defaultpp.png"
import "./Profile.css"
import { useNavigate } from "react-router-dom";
import { MdOutlineStorefront } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { IconContext } from "react-icons";
import { db } from './firebaseConfig';  
import { doc, getDoc } from 'firebase/firestore';
import { FaTags } from "react-icons/fa";


const Profile = () => {

  const checkIfFieldExists = async () => {
    const collectionName = 'users'; 
    const documentId = `+90${phoneNumber}`;  
    const fieldName = 'isSeller';  

    const docRef = doc(db, collectionName, documentId);  
    const docSnap = await getDoc(docRef);  

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];  
      if (fieldValue !== undefined) {
        return fieldValue;  
      } else {
        return null;  
      }
    } else {
      return null;  
    }
  };
  const checkIfLinkExists = async () => {
    const collectionName = 'users'; 
    const documentId = `+90${phoneNumber}`;  
    const fieldName = 'marketLink';  

    const docRef = doc(db, collectionName, documentId);  
    const docSnap = await getDoc(docRef);  

    if (docSnap.exists()) {
      const fieldValue = docSnap.data()[fieldName];  
      if (fieldValue !== undefined) {
        return fieldValue;  
      } else {
        return null;  
      }
    } else {
      return null;  
    }
  };
  


  const [name] = useState(localStorage.getItem("name")); // Başlangıç değeri boş
  const [phoneNumber] = useState(localStorage.getItem("phoneNum")); // Başlangıç değeri boş
  let navigate = useNavigate();
  let path = "#"
  const routeChange = (event) => {
    let id = event.target.id
    if (id === 'mylistings') {
      path = "/mylistings";
    } else if (id === 'signout') {
      localStorage.removeItem("isLoggedIn", "false");
      path = "/login";
    }
    navigate(path);
  }

const handleSearch = async () => {
      const fieldValue = await checkIfLinkExists();
      path = `/market-profile#${fieldValue}`;
      navigate(path);
      
};
    useEffect(() => {
      const isLoggedIn = localStorage.getItem("isLoggedIn");
      if (isLoggedIn !== "true") {
        navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
      }
      
      const testIsSeller = async () => {
        const fieldValue = await checkIfFieldExists();  
        if (fieldValue) { 
          localStorage.setItem("isSeller", "true");
          const marketlink = document.getElementById('market-profile');
          marketlink.style.display = 'flex';
        } else {
          localStorage.setItem("isSeller", "false");
          const marketlink = document.getElementById('market-profile');
          marketlink.style.display = 'none'
        }
    };
      testIsSeller();

    }, [navigate]); // navigate bağımlılığını ekliyoruz
    return (
      <div className='montainer'>
        <Header />
        <div className='topcontainer'>

          <div className='photo_holder'>
            <img className='profilephoto' alt="" src={PP}></img>
          </div>

        </div>

        <div className='detailedinformations'>
          <div className='textlabels'>Adım</div>
          <div className='inputdivs'><input className="isimkutusu" placeholder='Ad ve Soyad' type='text' value={name} readOnly ></input></div>
          <div className='textlabels'>Telefon Numaram</div>
          <div className='inputdivs'><input className="isimkutusu" placeholder='(5xx xxx xxxx)' type='number' value={phoneNumber} readOnly></input></div>
          <div onClick={routeChange} id="mylistings" className='tarlam'>
            <IconContext.Provider value={{ color: "white", className: "market-icon", size: "1.5em" }}>
            <FaTags />

            </IconContext.Provider>
            İlanlarımı Düzenle</div>
            <div onClick={handleSearch} id="market-profile" className='tarlam'>
            <IconContext.Provider value={{ color: "white", className: "market-icon", size: "1.5em" }}>
              <MdOutlineStorefront />
            </IconContext.Provider>
            Market Sayfam</div>
          <div onClick={routeChange} id="signout" className='logoutbut'>
            <IconContext.Provider value={{ color: "white", className: "signout-icon", size: "1.5em" }}>
              <TbLogout2 />
            </IconContext.Provider>
            Çıkış Yap</div>
        </div>
        <Footer />
      </div>
    )
  }

  export default Profile