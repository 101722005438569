import React, { useEffect , useState } from 'react'
import "./Newlisting.css"
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import { db } from "./firebaseConfig";
import { doc, setDoc } from "firebase/firestore"

const Newlisting = () => {
  let navigate = useNavigate();
  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn !== "true") {
      navigate("/home"); // Kullanıcı giriş yapmamışsa /home sayfasına yönlendir
    }
  }, [navigate]); // navigate bağımlılığını ekliyoruz


  return (
    <div className='mcontainer'>
      <div className='headercontainer'>
        <Header />
      </div>
      <div className="newsellertitle">Yeni İlan</div>
      <div className="newsellerholder">
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Satıcı Adı*</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="Kardeşler Çiftçilik"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Satıcı Numarası*</div>
          <div className='textboxholderdivs'><input type='number' className="textboxes" placeholder="(5xx xxx xxxx)"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>E-Posta Adresi</div>
          <div className='textboxholderdivs'><input  type="email" className="textboxes" placeholder="ornek@gmail.com"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Ürün Açıklaması*</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="En aşağıdaki açıklama"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Fiyat*</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="Kilo/Paket/Adet 100TL"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Saklama Koşulları</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="Soğuk zincir, oda sıcaklığı vb."></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Konum Bilgileri*</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="Ör. Ankara"></input></div>
        </div>
        <div className='doubleditemholder'>
          <div className='textboxtitles'>Alerjenler*</div>
          <div className='textboxholderdivs'><input  type="text" className="textboxes" placeholder="Gluten vb."></input></div>
        </div>
      </div>
      <div className='bottomholder'><div className='ilaniver'>İlanı Gönder</div></div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  )
}

export default Newlisting