import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import "./Listings.css"

const Listings = () => {
  return (
    <div className='mcontainer'>
      <Header/>
      <footer><Footer/></footer>
    </div>
  )
}

export default Listings