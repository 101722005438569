import React from 'react'
import "./Search.css"
import { IconContext } from "react-icons";
import testimg from "../assets/testimg.png";
import testimg2 from "../assets/testimg2.png";
import testimg3 from "../assets/testimg3.png";
import testimg4 from "../assets/testimg4.png";
import testimg5 from "../assets/testimg5.png";
import testimg6 from "../assets/testimg6.png";
import { MdOutlineShoppingCart } from "react-icons/md";

import { MdOutlineStorefront } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";




const Search = () => {

  return (
    <div className='fullcontainer'>
      <div className='title'>Vitrindekiler
      </div>

      <div className='vitrinyazi'>Meyve ve Sebzeler</div>
      <div className='vitrinmain'>
        <div className='items'>

          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                  <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg5}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg6}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg5}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg6}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
        </div>


      </div>
      <div className='vitrinyazi'>Ev Yapımı Ürünler</div>
      <div className='vitrinmain'>
        <div className='items'>

        <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg2}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg3}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                  <MdOutlineShoppingCart />
                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg4}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />

                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg2}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />

                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg3}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />

                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>
          <div class="item">
            <div className='imageholder'>
              <img className="testimg" alt="" src={testimg4}></img>
            </div>
            <div className='sellername'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Kardeşler Çiftlik
            </div>
            <div className='informationholder'>

              <div className='productname'>
                <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineShoppingCart />

                </IconContext.Provider>
                Üzüm
              </div>
              <div className='location'>
                Denizli
                <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                  <MdOutlineLocationOn />
                </IconContext.Provider>

              </div>
            </div>


          </div>





        </div>


      </div>



    </div>
  )
}

export default Search